import { ComponentOptions } from 'vue';
import { useUserStore } from '@/stores/user';
import { useStore } from '@/stores/main';
import { useOverlayStore } from '@/stores/overlay';
import { usePopupStore } from '@/stores/popup';
import { useSettingsStore } from '@/stores/settings';
import { mapStores } from 'pinia';
import { useSidePanelStore } from '@/stores/side-panel';
import { useSearchStore } from '@/stores/search';
import { IDocument, ROLES } from '@/types';
import { apis } from '@/api';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default {
  data() {
    return {
      ROLES
    };
  },
  computed: {
    ...mapStores(useStore, useUserStore, useOverlayStore, usePopupStore, useSidePanelStore, useSettingsStore, useSearchStore)
  },
  methods: {
    sanitizeUrl: sanitizeUrl,
    setDocumentTitle(pageTitle = null) {
      let title = this.$t('title');

      if (pageTitle) {
        pageTitle = this.$t(pageTitle);
        title = `${pageTitle} | ${title}`;
      }

      document.title = title;
    },
    downloadUrl(document: IDocument) {
      return sanitizeUrl(`${import.meta.env.VITE_API_URL}${apis.documents.download(document.id)}?bearer=${this.userStore.token}`);
    }
  }
} as ComponentOptions;
